<template>
  <div class="tgxxtj">
    <h3>资源资产流转数据统计</h3>
    <el-form :inline="true">
      <el-form-item label="统计时间：">
        <el-date-picker
          v-model="time"
          placeholder="选择开始日期"
          size="small"
          value-format="yyyy-MM-dd"
          :clearable="false"
          @change="changeTime"
        >
        </el-date-picker>
         至
        <el-date-picker
          v-model="time1"
          placeholder="选择结束日期"
          align="right"
          size="small"
          value-format="yyyy-MM-dd"
          :clearable="false"
          @change="changeTime1"
        >
        </el-date-picker>

        <!-- <el-date-picker
          v-model="time"
          type="daterange"
          range-separator="至"
          start-placeholder="开始日期"
          end-placeholder="结束日期"
          size="small"
          value-format="yyyy-MM-dd"
          @change="changeTime"
        >
        </el-date-picker> -->
      </el-form-item>
      <el-form-item label="区域：">
        <el-select v-model="selectAreacode" size="small" @change="changeArea">
          <el-option value="all" :label="identityObj.areaName">{{
            identityObj.areaName
          }}</el-option>
          <el-option
            v-for="(item, index) in options"
            :key="index"
            :value="item.xzqbh"
            :label="item.name"
            >{{ item.name }}</el-option
          >
        </el-select>
      </el-form-item>
      <span class="tip">(此图仅展示当年成交数据前六的区域)</span>
    </el-form>
    <BarChart
      class="chart1"
      :xData="xData"
      :seriesData="seriesData"
      :legend="legend"
      :changeEchart="changeEchart"
    />
    <h3 style="margin-top: 50px">
      {{ titleName }}资源资产流转数据统计<span
        >（统计时间：{{ startTime }}-{{ endTime }}）</span
      >
      <el-button type="primary" size="small" @click="exportStat"
        >导出统计结果</el-button
      >
    </h3>
    <FlowRecord
      v-if="isShow"
      @closeDialog="closeDialog"
      ref="ShowFlowRecord"
    ></FlowRecord>
    <!-- @row-click="ChangeTab" -->
    <el-table
      lazy
      :load="getChildrens"
      :data="list"
      :border="true"
      style="width: 100%"
      :header-cell-style="{
        textAlign: 'center',
        'background-color': '#f8f8f9',
      }"
      :cell-style="{
        textAlign: 'center',
      }"
      row-key="xzqbh"
      :tree-props="{ children: 'children', hasChildren: 'hasChildren' }"
    >
      <el-table-column
        prop="xzqName"
        label="区域"
        width="150"
      ></el-table-column>

      <el-table-column props="area" label="总面积(亩)" width="100">
        <template slot-scope="scope">
          <span>{{
            scope.row.area ? parseFloat(scope.row.area).toFixed(2) : "-"
          }}</span>
        </template>
      </el-table-column>

      <el-table-column props="amount" label="总金额(万元)" width="80">
        <template slot-scope="scope">
          <span>{{
            scope.row.amount
              ? parseFloat(scope.row.amount / 10000).toFixed(2)
              : "-"
          }}</span>
        </template>
      </el-table-column>

      <el-table-column prop="areaFarmer" label="农户流转数量(亩)" width="120">
        <template slot-scope="scope">
          <span>{{
            scope.row.areaFarmer
              ? parseFloat(scope.row.areaFarmer).toFixed(2)
              : "-"
          }}</span>
        </template>
      </el-table-column>

      <el-table-column prop="amountFarmer" label="农户流转金额（万元）">
        <template slot-scope="scope">
          <span>{{
            scope.row.amountFarmer
              ? parseFloat(scope.row.amountFarmer / 10000).toFixed(2)
              : "-"
          }}</span>
        </template>
      </el-table-column>

      <el-table-column prop="areaGroup" label="集体流转数量(亩)" width="120">
        <template slot-scope="scope">
          <span>{{
            scope.row.areaGroup
              ? parseFloat(scope.row.areaGroup).toFixed(2)
              : "-"
          }}</span>
          <span> </span>
        </template>
      </el-table-column>

      <el-table-column
        prop="amountGroup"
        label="集体流转金额(万元)"
        width="120"
      >
        <template slot-scope="scope">
          <span>{{
            scope.row.amountGroup
              ? parseFloat(scope.row.amountGroup / 10000).toFixed(2)
              : "-"
          }}</span>
        </template>
      </el-table-column>

      <el-table-column
        prop="areaBuild"
        label="集体出让建设用地(亩)"
        width="135"
      >
        <template slot-scope="scope">
          <span>{{
            scope.row.areaBuild
              ? parseFloat(scope.row.areaBuild).toFixed(2)
              : "-"
          }}</span>
        </template>
      </el-table-column>

      <el-table-column prop="areaManage" label="承包经营权流转(亩)" width="130">
        <template slot-scope="scope">
          <span>{{
            scope.row.areaManage
              ? parseFloat(scope.row.areaManage).toFixed(2)
              : "-"
          }}</span>
        </template>
      </el-table-column>

      <el-table-column prop="areaManage" label="操作" width="100">
        <template slot-scope="scope">
          <el-button
            v-if="scope.row.xzqbh.length == 12"
            type="text"
            @click="FlowRecord(scope.row)"
            >流转记录</el-button
          >
        </template>
      </el-table-column>
    </el-table>
  </div>
</template>
<script>
import {
  getStatLzData,
  statLzDataTopN,
  getXzqArr,
  exportLzData,
  selectListById,
} from "@/api/add";
import BarChart from "@/components/echart/zyzctjChart.vue";
import FlowRecord from "./flowRecord";
import { mapGetters } from "vuex";

export default {
  components: {
    BarChart,
    FlowRecord,
    // TablePage,
  },
  data() {
    return {
      // time: ["", ""],
      time:'',
      time1:'',
      area: "",
      list: [],
      xData: [""],
      titleName: "",
      selectAreacode: "all",
      changeEchart: false,
      isShow: false,
      props: {
        label: "areaName",
        children: "children",
        value: "id",
        fullPathName: "fullPathName",
        checkStrictly: true,
        emitPath: false,
      },
      options: [],
      areacode: "",
      tableList: [],
      flag: "",
      legend: [],
      seriesData: [],
      baseData: [
        {
          name: "面积（亩）",
          key: "area",
          value: [],
        },
        {
          name: "金额（万元）",
          key: "amount",
          value: [],
        },
      ],
      tableData: [],
    };
  },
  created() {
    // this.selectAreacode = this.identityObj.areaCode
    this.deptId = this.userInfo.deptId;
    console.log(this.deptId, "查看这个id");
    this.startTime = this.$moment().format("YYYY-01-01");
    this.endTime = this.$moment().format("YYYY-MM-DD");
    this.time = this.startTime;
    this.time1 = this.endTime;
    this.titleName = this.identityObj.areaName;
    this.areacode = this.identityObj.areaCode;
    this.flag = this.identityObj.identityTypeCode == 7 ? "Y" : "N";
    this.initData();
    this.getList();
    this.getRegions();
    this.getSeriesData();
  },
  computed: mapGetters(["userInfo", "identityObj"]),
  methods: {
    closeDialog() {
      this.isShow = false;
    },
    // 获取表格标题
    getTitleName(val) {
      let name = "";
      this.options.forEach((item) => {
        if (item.id == val) {
          name = item.areaName;
        }
      });
      return name;
    },
    getTitledeptId(val) {
      console.log(val, "查看val");
      console.log(this.options, "查看  this.options");
      let name = "";
      this.options.forEach((item) => {
        if (item.xzqbh == val) {
          name = item.deptId;
        }
      });
      return name;
    },
    // 初始化统计图数据
    async initData() {
      let xData = [];
      this.seriesData = this.seriesData.map((x) => {
        x.data = [];
        return x;
      });
      let res = await statLzDataTopN({
        // topN: 10,
        flag: this.flag,
        xzqbh: this.areacode,
        startTime: this.startTime,
        endTime: this.endTime,
        deptId: this.deptId,
      });
        console.log(this.seriesData,'查看seriesData')
      // res.data = res.data.filter((x) => {
      //   return x.areaCode != this.areacode;
      // });

      res.data.forEach((item) => {
        if (item.cqlxArrayVo) {
          xData.push(item.areaName);
        }
        this.seriesData.map((x) => {
          let include = false;
          if (item.cqlxArrayVo) {
            item.cqlxArrayVo.map((y) => {
              if (x.cqlxbh == y.cqlxType) {
                include = true;
                if (x.stack == "cjJe") {
                  x.data.push((parseFloat(y[x.stack]) / 10000).toFixed(2));
                } else {
                  x.data.push(y[x.stack]);
                }
              }
            });
          }
          if (!include) {
            x.data.push(undefined);
          }
        });
      });

      // console.log('this.xData',   this.xData)
      this.xData = xData.slice(0, 6);
      console.log(' this.seriesData', this.seriesData)
      this.changeEchart = !this.changeEchart;
    },



    // 根据产权类型处理统计图图例
    getSeriesData() {
      let types = JSON.parse(localStorage.getItem("types"));
      if (types && types.length > 0) {
        types.map((x) => {
          this.seriesData.push({
            name: x.name,
            cqlxbh: x.bh,
            type: "bar",
            stack: "cjArea",
            emphasis: {
              focus: "series",
            },
            data: [],
          });
          this.seriesData.push({
            name: x.name,
            cqlxbh: x.bh,
            type: "bar",
            stack: "cjJe",
            emphasis: {
              focus: "series",
            },
            data: [],
          });
          this.legend.push(x.name);
        });

      }

    },



    // 获取底部表格数据
    async getList() {
      let tableList = [];
      let res = await getStatLzData({
        current: 1,
        size: 10,
        xzqbh: this.areacode,
        startTime: this.startTime,
        endTime: this.endTime,
        deptId: this.deptId,
      });
      let { status, data, message } = res;
      // console.log("切换地区执行的数据", res);
      if (status == 200) {
        this.list = data.records;
        // this.list = data.records.filter((x) => {
        //   return x.xzqbh != this.areacode;
        // });
        // console.log(this.list,'查看里面的值')
        this.list.forEach((item) => {
          if (item.xzqbh.length < 12) {
            const newItem = {
              ...item,
              hasChildren: true,
              children: [],
            };
            // console.log(newItem,'这里应该也要执行')
            tableList.push(newItem);
            this.list = tableList;
            // console.log(this.list,'查看这个List有值嘛')
          }
        });
      }
    },
    // 切换时间
    changeTime(val) {
      console.log(val);
      // if (val == 1) {
        this.startTime = val;
      // } else {
      //   this.endTime = val[1];
      // }

      this.initData();
      this.getList();
    },
    // 切换时间
    changeTime1(val) {
      console.log(val);
      // if (val == 1) {
      //   this.startTime = val[0];
      // } else {
        this.endTime = val;
      // }

      this.initData();
      this.getList();
    },
    // 切换地区
    changeArea(val) {
      console.log(val, "查看val");
      if (val === "all") {
        this.titleName = this.identityObj.areaName;
        this.areacode = this.identityObj.areaCode;
        this.deptId = this.userInfo.deptId;
        this.flag = this.identityObj.identityTypeCode == 7 ? "Y" : "N";
        this.initData();
        this.getList();
      } else {
        this.areacode = val;
        this.titleName = this.getTitleName(val);
        this.deptId = this.getTitledeptId(val);
        // console.log( this.deptId,'查看this.depId')
        console.log(this.deptId, "查看这里的this.deptid");
        this.flag = "N";
        this.initData();
        this.getList();
      }
    },
    //导出统计结果
    async exportStat() {
      let params = {
        xzqbh: this.areacode,
        startTime: this.startTime,
        endTime: this.endTime,
        deptId: this.deptId,
      };
      let res = await exportLzData(params);
      if (res) {
        let blob = res;
        const fileName = this.titleName + "资源资产流转数据统计.xlsx";
        let downloadElement = document.createElement("a");
        let binaryData = [];
        binaryData.push(blob);
        let href = window.URL.createObjectURL(
          new Blob(binaryData, { type: "application/pdf" })
        ); //创建下载的链接
        downloadElement.href = href;
        downloadElement.download = decodeURIComponent(fileName); //解码
        document.body.appendChild(downloadElement);
        downloadElement.click();
        document.body.removeChild(downloadElement);
        window.URL.revokeObjectURL(href);
      }
    },
    // 获取行政区树
    async getRegions() {
      let length = this.identityObj.areaCode.length;
      // let parentCode = ''
      let level = null;
      switch (length) {
        case 4:
          level = 3;
          break;
        case 6:
          // parentCode = this.identityObj.areaCode.slice(0, 4)
          level = 4;
          break;
        case 9:
          // parentCode = this.identityObj.areaCode.slice(0, 6)
          level = 5;
          break;
        case 12:
          // parentCode = this.identityObj.areaCode.slice(0, 9)
          level = 5;
          break;
      }
      // let res = await getXzqArr({
      //   level: level,
      //   parentCode: this.identityObj.areaCode,
      // });
      // if (res.code == 0) {
      //   this.options = res.data;
      // }
      // let level = 3;
      // if (this.identityObj.areaCode.length == 6) {
      //   level = 4;
      // }
      // if (this.identityObj.areaCode.length == 4) {
      //   level = 3;
      // }
      let res = await selectListById({
        deptId: this.userInfo.deptId,
        areaCode: this.identityObj.areaCode,
      });
      if (res) {
        this.options = res;
      }
    },
    //点击获取流转记录
    FlowRecord(obj) {
      console.log(this.time,'查看this.time')
      this.isShow = true;
      this.$nextTick(() => {
        this.$refs.ShowFlowRecord.initObj(obj, this.time,this.time1);
      });
    },

    getChildrens(tree, treeNode, resolve) {
      let tableList = [];

      setTimeout(async () => {
        let res = await getStatLzData({
          // topN: 10,
          xzqbh: tree.xzqbh,
          startTime: this.startTime,
          endTime: this.endTime,
          deptId: tree.deptId,
        });
        res.data.records.forEach((item, index) => {
          if (item.xzqbh.length < 12) {
            const newItem = {
              ...item,
              hasChildren: true,
              children: [],
            };
            tableList.push(newItem);
            res.data.records = tableList;
          }
        });
        // res.data.forEach((item, index) => {
        //   item.xzqbh = item.areaCode; //地址
        //   item.xzqName = item.areaName; //地址名字
        //   item.area = item.totleArea; //总面积
        //   item.areaFarmer = item.totleArea; //农户流转数量
        //   item.amount = item.totleJe; //总金额
        //   if (item.areaCode.length < 12) {
        //     const newItem = {
        //       ...item,
        //       hasChildren: true,
        //       children: [],
        //     };
        //     tableList.push(newItem);
        //     res.data = tableList;
        //   }
        // });
        resolve(res.data.records);
      }, 1000);
    },
  },
};
</script>
<style lang="less" scoped>
/deep/ .el-form-item .el-input {
 width: 135px !important;
}
/deep/.el-form {
  .el-form-item {
    margin-bottom: 10px;
    margin-left: 12px;
  }
  .tip {
    display: inline-block;
    float: right;
    margin-right: 20px;
    color: #7f7f7f;
    line-height: 40px;
  }
}
h3 {
  span {
    display: inline-block;
    font-weight: normal;
    font-size: 16px;
  }
  .el-button {
    float: right;
  }
}
.tgxxtj {
  box-sizing: border-box;
  padding: 0 30px 24px 30px;
  .chart1 {
    height: 500px;
    width: 100%;
  }
  table {
    border-collapse: collapse;
    border-color: #c0c4cc;
    width: 100%;
    thead {
      background: #f3f3f3;
      td {
        text-align: center;
        padding: 10px 0;
      }
    }
    tbody {
      td {
        text-align: center;
        padding: 8px 0;
      }
    }
  }
}
</style>
